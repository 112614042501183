import React, { Component } from 'react'
import { Table } from 'reactstrap';
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helmet } from "react-helmet";
import { Form, Row, Col, DropdownButton, Dropdown, } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import '../style/gold2.css'
import { cap } from '../params'
import path from '../assets/img/gold2/path1.svg'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import music from '../assets/music/fella.mp3'
import logoig from '../assets/img/gold2/logoig.svg'
import path3 from '../assets/img/gold1/path3.svg'
import covid from '../assets/img/fella/covid.svg'
import post from '../params/post'
import getData from '../params/getdata'
import '../style/sty.scss'
import path2 from '../assets/img/gold1/path2.svg'
import bunga6 from '../assets/img/bunga6.png'
import logo from '../assets/img/logo.ico'
import { gambar } from '../params'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let modal = "https://i.ibb.co/VS7Wm4p/Pics-Art-12-03-09-56-53.jpg"
let openlogo = "https://i.ibb.co/vz2WMnb/Logo.png"
let cmain = '#77411a'
let black = 'rgb(38,38,38)'

let id = 'fella-fakhri'
let inisial_co = 'Fakhri'
let inisial_ce = 'Fella'

let lengkap_co = (<>Fakhriyan Ardyanto</>)
let lengkap_ce = (<>Fella Shofa</>)

let bapak_co = 'Bpk Agus Sudaryanto '
let ibu_co = 'Ibu Ninuk Warsini'
let bapak_ce = "Bpk Agung Nasocha "
let ibu_ce = "Ibu Nurhanilah"

let ig_co = "Bigfootontrip"
let ig_ce = "Fellashofa"

let foto_ce = "https://i.ibb.co/WkXhcj5/Individual-2.jpg"
let foto_co = "https://i.ibb.co/XxHVgJB/Individual.jpg"
let waktunikah = "01/13/2021"

let gmaps = "https://goo.gl/maps/2fNeRJ7DhcyanNWw9"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NXZrbzBxaDhwMTRqaTFzZnJ0MzM0YjExdnYgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = ["	https://i.ibb.co/6w67fkj/IMG-6340.jpg	",
    "	https://i.ibb.co/w0NYDp5/IMG-6344.jpg	",
    "	https://i.ibb.co/xs2W2Yw/IMG-6349.jpg	",
    "	https://i.ibb.co/jVVnBBh/IMG-6353.jpg	",
    "	https://i.ibb.co/F0qY560/IMG-6355.jpg	",
    "	https://i.ibb.co/vDk9Lxr/IMG-6378-1.jpg	",
    "	https://i.ibb.co/2nBTm51/IMG-6381.jpg	",
    "	https://i.ibb.co/vwLTSPp/IMG-6393.jpg	",
    "	https://i.ibb.co/kDwXqD6/IMG-6394.jpg	",
]

let foto = "https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg"
let musik = "https://www.flaticon.com/svg/static/icons/svg/926/926338.svg"
let flash = "https://www.flaticon.com/svg/static/icons/svg/861/861496.svg"
let speech = "https://www.flaticon.com/svg/static/icons/svg/3094/3094755.svg"

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            clicked: 0,
            hadir: true,
            err: [],
            submitted: '',
            friend: null,

        }
    }



    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 1000
        });
        let acara = [[
            'Akad Nikah',
            '20 Desember 2020',
            (<><b>Grand Keisha Hotel Yogyakarta </b><br />Jl. Affandi No 9,
            Gejayan, Sleman, Daerah Istimewa Yogyakarta</>),
            '10.00 WIB - Selesai'
        ],
        [
            'Intimate Wedding',
            '20 Desember 2020',
            (<>Grand Keisha Hotel Yogyakarta <br />Jl. Affandi No 9,
                Gejayan, Sleman, Daerah Istimewa Yogyakarta</>),
            '11:00 WIB - Selesai'
        ]
        ]
        this.setState({ acara: acara })
        var countDownDate = new Date("12/20/2020").getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })

        }, 1000);

    }

    dropdown = () => {
        let { friend } = this.state
        let item = []
        for (let index = 1; index < 3; index++) {
            item[index] = (<Dropdown.Item onClick={() => {
                this.setState({ friend: index })
            }}>{index}</Dropdown.Item>)
        }
        return (
            <>
                <Item>
                    <p style={{ fontSize: '16px', color: cmain }} className="mb-0 w-100 text-center">
                        How many people you coming with?
          </p>
                    <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
                        {
                            item
                        }
                    </DropdownButton>
                </Item>
            </>
        )
    }
    play = () => {

        var snd = new Audio(music);
        snd.type = 'audio/mp3';
        snd.play();
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            behavior: 'smooth'
        })
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('sectiongold24');
            elmnt.scrollIntoView();
        }, 1000)
        setTimeout(() => {
            AOS.refresh()
        }, 1100)

    }
    handleSubmit = () => {

        let local = localStorage.getItem('pesan')
        let err = []
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }

        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "${this.state.friend}", kepada: "fella-fakhri", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                            
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });

        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }



    render() {

        let { clicked, acara, days, hours, minutes, seconds, hide, hadir, err, submitted, sesi } = this.state

        let storyf = [
            "	https://i.ibb.co/NyrSDz7/story1.jpg	",
            "	https://i.ibb.co/NmXFJdx/story2.jpg	",
            "	https://i.ibb.co/PFPwbGj/story3.jpg	",
            "	https://i.ibb.co/0ss2j86/story4.jpg	",
            "	https://i.ibb.co/C0jmgmx/story5.jpg",

        ]
        let caption = [
            (<><b>2012</b><br />Cerita cinta kami berawal pada penghujung tahun 2012, tepatnya bulan Desember. Usia perkenalan yang singkat, bukan halangan membangun jalinan asmara. Gejolak emosi di usia remaja tidak bisa dipungkiri, jadi bara yang membuat kami sedikit ragu "apa tujuan hubungan ini?".
            </>),
            (<><b>2013-2016</b><br />Sekian tahun berselang, tak terasa hubungan kami menginjak tahun ke-3. Romansa percintaan masih hangat, tapi bukan tanpa masalah. Hadirnya ego sempat jadi candu bagi kami, "apakah ini fase pendewasaan?".
            </>),
            (<><b>2017 - 2018</b><br />Kampus almamater biru jadi saksi kami mengampu ilmu. Tapi tidak di kejuruan yang sama. Pilihan sudah ditentukan dari awal, esok jadi apa baru sebatas menghayal. Kendati demikian, menyandang gelar sarjana tepat waktu jadi raihan yang membanggakan. Sederhana setelahnya, kami hanya ingin mencari sedikit receh untuk ditabung. Sebatas mengharap, agar kelak pergi belanja tanpa melihat harga.
            </>),
            (<><b>2019</b><br />Masing-masing dari kami terus menua. Arah hubungan ini masih perlu jawaban. Kami saling berpandangan, pertanyaan sanak famili jadi racun yang mendebarkan. Tepat bulan September 2019, pintu pertemuan kedua keluarga terbuka. Bermodal restu, dua insan akhirnya menjadi satu.
            </>), (<><b>2020</b><br />Delapan tahun menjalin hubungan. Tibalah waktu bagi kami, mengikat janji suci sehidup semati. Tidak ada lagi tanda tanya, terjawab harapan sederhana untuk bahagia bersama selamanya.
            </>)]

        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''
        console.log(clicked)

        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })
        return (
            <>
                <Helm
                    title='Undanganku - Pernikahan Fella & Fakhri'
                    desc="Undangan berbasis online harga mulai 100 ribuan, responsive, interaktif, dan modern"
                    logo={logo}
                />
                <div id='gold2'>
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw'
                    }}>
                        {
                            this.useQuery().get('x') == "x" ? (<Float />) : false
                        }

                        <Container fluid id='g3-header' className='position-relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <div className={`position-absolute w-100 ${hide ? 'hide' : 'show'}`}
                                style={{ bottom: "-2px", left: 0 }}>
                                <img src={path} className='img-fluid w-100' />
                            </div>
                            <div className="position-absolute" style={{ left: 0, bottom: '10%', width: '100vw' }}>
                                <Item>
                                    <Col xs={12} md={4} className='m-2 m-md-0 '>
                                        <img className='img-fluid w-100 p-2'
                                            src={gambar(openlogo)} data-aos="fade-left" data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    {
                                        <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`}
                                            style={{ marginTop: '0' }} data-aos="fade-right">
                                            Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                    }</Item>
                                <Row className='justify-content-center'>
                                    <div onClick={() => { this.play() }}
                                        className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                        style={{ marginTop: 0, color: 'white' }}>
                                        Open Invitation
                            </div>
                                </Row>

                            </div>
                        </Container>


                        <div className={hide ? 'd-none' : 'd-block'}>


                            <Container id='sectiongold24' className='pb-md-5' ref={this.myRef}>

                                <div className='pt-5 pb-5'>

                                    <Item>
                                        <Col xs={4} lg={1}>
                                            <img src={path2} className='img-fluid w-100' data-aos="fade-left" data-aos-duration="1000" />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center col-md-8 quotes pt-3 pb-md-5' data-aos="fade-left"
                                            data-aos-duration="1000">
                                            “And of His signs is that He created for you from yourselves mates that youmay
                                            find tranquility in them; and He placed between you affection and mercy.Indeed
                                                in that are signs for a people who give thought.” <br /><br />(Ar-Rum: 21)
                                            </p>
                                    </Item>

                                </div>
                            </Container>




                            <Container id='mempelai'>
                                <Item>
                                    <Col xs={12} md={10}>
                                        <div className='opening' data-aos="zoom-in">
                                            Maha Suci Allah yang telah menciptakan makhluk-Nya secara Berpasang-pasangan.
                                            Ya Allah perkenankan kami merangkai kasih sayang yang kau ciptakan diantara Putra Putri kami.
                                        </div>
                                        <Item>
                                            <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                                <div className='kotak mr-lg-2'>
                                                    <Item>
                                                        <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                            {inisial_ce}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <Col xs={6}>
                                                            <img src={gambar(foto_ce, 90)}
                                                                className='img-fluid w-100 ' style={{ borderRadius: '15px' }} />
                                                        </Col>
                                                    </Item>
                                                    <Item>
                                                        <h1 className="py-3 w-100 text-center"
                                                            style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                            {lengkap_ce}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                            <b>Putri Ketiga dari :</b><br />
                                                            {bapak_ce}  <br />
                                                        &<br />
                                                            {ibu_ce}
                                                        </p>
                                                    </Item>
                                                    <Item>

                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                    </Item>

                                                </div>
                                            </div>
                                            <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                                <div className='kotak mr-lg-2'>
                                                    <Item>
                                                        <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                            {inisial_co}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <Col xs={6}>
                                                            <img src={gambar(foto_co, 90)} style={{ borderRadius: '15px' }}
                                                                className='img-fluid w-100' />
                                                        </Col>
                                                    </Item>
                                                    <Item>
                                                        <h1 className="py-3 w-100 text-center" style={{
                                                            fontSize: '32px',
                                                            fontFamily: "'Marck Script', cursive", color: cmain
                                                        }}>
                                                            {lengkap_co}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                            <b>Putra Pertama dari:</b><br />
                                                            {bapak_co}
                                                            <br />
                                                        &<br />
                                                            {ibu_co}
                                                        </p>
                                                    </Item>
                                                    <Item>
                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                    </Item>

                                                </div>
                                            </div>
                                        </Item>
                                    </Col>
                                </Item>
                            </Container>



                            <Container id='sectiongold27'>
                                <div className='pt-3 ml-3 mr-3'>
                                    <Item>
                                        <Col xs={4} lg={2}>
                                            <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <Col xs={12} md={8} className='katok'>

                                            <Container fluid className="text-center px-4 dinny" style={{ color: cmain }} >
                                                <Item>
                                                    <p className="fs16">
                                                        The wedding will be held on :
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <p className="fs20">
                                                        <b>
                                                            Sunday <span className="fs36">20</span> DEC 2020
                                                        </b>
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <p className="fs20 col-md-6 p-3" style={{ color: cmain }}>
                                                        <b className="allura" style={{ fontSize: '36px' }}>
                                                            <img src={'https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg'}
                                                                width="50px" height="50px" />
                                                                <br/>
                                                            Akad Nikah</b><br />

                                                        <div className="py-3">
                                                            10.00 WIB - Selesai
                                                        </div>
                                                        <br />
                                                        <span className=" fs16">

                                                            <b>Grand Keisha Hotel Yogyakarta </b><br />Jl. Affandi No 9,
                                                                Gejayan, Sleman, Daerah Istimewa Yogyakarta 

                                                        </span>
                                                    </p>
                                                    <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid #86835dff` }}>
                                                    </div>
                                                    <p className="fs20 p-3 col-md-6"
                                                        style={{ color: cmain }}>
                                                        <b className="allura" style={{ fontSize: '36px' }}>
                                                            <img src={'https://www.flaticon.com/svg/static/icons/svg/703/703213.svg'}
                                                                width="50px" height="50px" /><br/>
                                                            Intimate Wedding</b><br />
                                                        <div className="py-3">
                                                            11.00 WIB - Selesai</div>
                                                        <br />
                                                        <span className=" fs16">
                                                            <b>Grand Keisha Hotel Yogyakarta </b><br />Jl. Affandi No 9,
                                                            Gejayan, Sleman, Daerah Istimewa Yogyakarta 
                                                        </span>
                                                    </p>
                                                </Item>
                                                
                                                <p style={{ fontSize: '24px' }} className="w-100 text-center py-3">
                                                <img src={'https://i.ibb.co/VwXn6PL/dress.png'}
                                                                width="60px" height="60px" /><br/>
                                                                <b>“Dresscode : All White “</b>
                                                </p>
                                            </Container>


                                        </Col>
                                    </Item>
                                    <Container className="text-center py-5 dinny" id="dinny">

                                        <>
                                            <Item>
                                                <h1 style={{
                                                    fontSize: '72px',
                                                    fontFamily: "'Marck Script', cursive",
                                                    color: cmain
                                                }}>
                                                    Rundown
                                            </h1>
                                            </Item>

                                            {
                                                [
                                                    { waktu: "	09.00 -10.00	", acara: "	Akad Nikah", icon: "	https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg" },
                                                    { waktu: "	10.45	", acara: "	Wedding Entrance	", icon: "	https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg	" },
                                                    { waktu: "	11.00	", acara: "	Speech, Mingle	", icon: "	https://www.flaticon.com/svg/static/icons/svg/3094/3094755.svg	" },
                                                    { waktu: "	11.25	", acara: "	Flashmob and Dance	", icon: "	https://www.flaticon.com/svg/static/icons/svg/861/861496.svg	" },
                                                    { waktu: "	11.45	", acara: "	Photo Session, Games	", icon: "	https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg	" },
                                                    { waktu: "	12.00	", acara: "	Enjoy the Live Music	", icon: "	https://www.flaticon.com/svg/static/icons/svg/926/926338.svg	" },
                                                    {waktu:'12.25',acara:'Wedding bouquet tos',icon:'https://www.flaticon.com/svg/static/icons/svg/2916/2916686.svg'}

                                                ].map((v, i) => {
                                                    i++
                                                    console.log(i)
                                                    return (
                                                        <Row className="py-2">
                                                            <Col className={i % 2 ? "order-md-1" : "order-md-2"}>
                                                                <Row >
                                                                    <Col md={3} xs={3} className={`order-1 ${i % 2 ? "order-md-2" : "order-md-1"}`}>
                                                                        <img src={v.icon} className="img-fluid w-100 px-1 px-md-2 my-auto" />
                                                                    </Col>
                                                                    <Col xs={8} className={`order-1 text-left ${i % 2 ? "order-md-1 text-md-right" : "order-md-2 text-md-left"}`}>
                                                                        <p className=" fs16">
                                                                            <b>
                                                                                {v.waktu}
                                                                            </b><br />
                                                                            {v.acara}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className={`d-none d-md-block ${i % 2 ? "order-md-2" : "order-md-1"}`}>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }

                                        </>
                                    </Container>


                                    <Item>
                                        <div data-aos="zoom-in"
                                            data-aos-duration="1000"
                                            className='col-6 col-lg-4 mt-3 button text-center btn p-0'
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}>
                                            Get Direction
                </div>
                                    </Item>
                                    <Item>
                                        <div data-aos="zoom-in"
                                            data-aos-duration="1000" className='btn col-6 col-lg-4 mt-3 button text-center smaller' onClick={() => {
                                                this.googleCalendar()
                                            }} onClick={() => {
                                                window.open(gcalendar)
                                            }}>
                                            Add to Google Calendar
                </div>
                                    </Item>

                                    <Container fluid className="py-3" data-aos="fade-right">
                                        <Item>
                                            <Col xs={12} md={6}>
                                                <img src={covid} className="w-100 img-fluid" />
                                            </Col>
                                        </Item>
                                    </Container>

                                    <Item>
                                        <div data-aos="fade-left" data-aos-duration="1000" className='col-10 col-lg-8 kotak'>
                                            <Item>
                                                <div className='item'>
                                                    <Item>
                                                        <div>
                                                            {days}
                                                        </div>
                                                    </Item>
                                                    <Item>
                                                        <span>
                                                            Days
                      </span>
                                                    </Item>
                                                </div>
                                                <div className='dot'>:</div>
                                                <div className='item'>
                                                    <Item>
                                                        <div>
                                                            {hours}
                                                        </div>
                                                    </Item>
                                                    <Item>
                                                        <span>
                                                            Hours
                      </span>
                                                    </Item>
                                                </div>
                                                <div className='dot'>:</div>
                                                <div className='item'>
                                                    <Item>
                                                        <div >
                                                            {minutes}
                                                        </div>
                                                    </Item>
                                                    <Item>
                                                        <span>
                                                            Mins
                      </span>
                                                    </Item>
                                                </div>
                                                <div className='dot' >:</div>
                                                <div className='item'>
                                                    <Item>
                                                        <div>
                                                            {seconds}
                                                        </div>
                                                    </Item>
                                                    <Item>
                                                        <span>
                                                            Secs
                      </span>
                                                    </Item>
                                                </div>
                                            </Item>

                                        </div>
                                    </Item>
                                </div></Container>

                            <Container fluid className="p-0">
                                <div className='w-100' style={{ bottom: 0, left: 0 }} >
                                    <img src={path3} className='img-fluid w-100' />
                                </div>
                            </Container>
                            <Container fluid style={{ backgroundColor: 'white' }}>
                                <Container className="py-5 px-md-5 col-md-8 px-3"
                                    style={{ color: cmain, fontFamily: 'Patrick Hand, cursive', backgroundColor: 'white' }}
                                >

                                    <Row className="position-relative px-5 py-5">
                                        <h1 className="peri w-100 text-center">
                                            Our Love Story
                                    </h1>
                                        {storyf.map((v, i) => {
                                            return (
                                                <Item>
                                                    <Col xs={12} md={5} className={`${i % 2 ? 'order-md-2' : 'order-md-1'} text-center `}>
                                                        <img src={gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=500&h=500')} className="img-fluid w-100 p-3" />
                                                    </Col>
                                                    <Col xs={12} md={7} className={i % 2 ? 'order-md-1' : 'order-md-2'} >
                                                        <Row className="h-100">
                                                            <p className="w-100 text-center my-auto" style={{ fontSize: '18px' }}>
                                                                {caption[i]}
                                                            </p>
                                                        </Row>
                                                    </Col>
                                                </Item>
                                            )
                                        })}
                                    </Row>
                                </Container>


                            </Container>
                            <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                <Slider slide={slider} />
                            </Container>
                            <Container id='sectiongold58' >

                                <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                    <Item>
                                        <Col xs={4} lg={2}>
                                            <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                            <Item>
                                                <h1 style={{
                                                    fontFamily: '"Marck Script", cursive',
                                                    color: cmain
                                                }}>
                                                    Send Your Wishes
                                                </h1>
                                            </Item>
                                            <Item>
                                                <form className="col-12 w-100">
                                                    <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                    <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                    {
                                                        this.dropdown()
                                                    }
                                                    <Item>
                                                        <div id="formradio">
                                                            <div class="custom_radio row justify-content-center">
                                                                <div onClick={() => {
                                                                    this.setState({ hadir: true })
                                                                }
                                                                }>
                                                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                    <label for="featured-1">Hadir</label>
                                                                </div>
                                                                <div onClick={() => {
                                                                    this.setState({ hadir: false })
                                                                }
                                                                } className="pl-5">
                                                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                    <label for="featured-2"

                                                                    >Tidak Hadir</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Item>

                                                    <Item>
                                                        <Col xs={12} className=''>
                                                            {
                                                                submitted == true ? (
                                                                    <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                        Pesan anda sudah disampaikan
                                                                    </Alert>) : (submitted === false ? (
                                                                        <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                            {
                                                                                err.map(val => {
                                                                                    return (
                                                                                        <li>{val}</li>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </Alert>
                                                                    ) : false)
                                                            }

                                                        </Col>
                                                    </Item>
                                                    <Item>
                                                        <div className='col-6 button rounded btn'
                                                            onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                    </Item>
                                                </form>
                                            </Item>
                                        </div>
                                    </Item>
                                </div>
                            </Container>

                            <Container id='sectiongold210' >
                                <div className='path w-100 '>
                                    <img src={path3} className='img-fluid w-100 ' />
                                </div>
                            </Container>
                            <Container fluid style={{ backgroundColor: 'white' }}>
                                <Foot ig={logoig} dark />

                            </Container>
                        </div>
                    </div>
                </div>
            </>


        )
    }
}